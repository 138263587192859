<template>
  <div class="warehousePdaLayout">
    <Navbar/>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/layout/components/Navbar";
import {StorageLocationExportReqVO} from "@/ts/StorageController";
export default {
  name: "WarehousePdaLayout",
  description: '创建WarehousePdaLayout,把pda端的页面都挂为二级路由,那么就可以在这个页面统一初始化Navbar的逻辑(初始化设置仓库id的逻辑)',
  components: {Navbar},
  data(){
    return {
      storageLocationExportReqVOGetStorageLocationList: new StorageLocationExportReqVO(),
    }
  },
  computed:{
    storageLocationNo_id_map(){
      const storageLocationNo_id_map = {}
      for (let storageLocation of this.storageLocationExportReqVOGetStorageLocationList.responseData) {
        storageLocationNo_id_map[storageLocation.locationNo] = storageLocation.id
      }
      return storageLocationNo_id_map
    }
  },
  created() {
    // console.log('WarehousePdaLayout load', this);

    // todo 目前的pda交互不太好改动, 先优化, 减少返回的数据, 减少调用, 后面再调整
    this.storageLocationExportReqVOGetStorageLocationList.directCall(response => {
      this.storageLocationExportReqVOGetStorageLocationList.responseData = response.data
      // 库位不刷新,这里需要手动刷新
      // this.$forceUpdate()
    })

  }
}
</script>

<style scoped lang="scss">
.warehousePdaLayout ::v-deep{
 .navbar{
   #hamburger-container{
     display: none;
   }
   #breadcrumb-container{
     display: none;
   }
   .right-menu{
     display: flex;
     #selectWebsite{
       display: none;
     }
     #selectWarehouse{
       width: 300px;
     }
     #screenfull{
       display: none;
     }
     #size-select{
       display: none;
     }
     .avatar-container{
       display: none;
     }
   }
 }
}
</style>
