import {
  ExtraPhotoQueryStatisticsRequest,
  ExtraPhotoQueryStatisticsRequest$Response,
} from "@/ts/ValueAddedServiceController";

const state = {
  menuStatistics: new ExtraPhotoQueryStatisticsRequest$Response()
}

const mutations = {
  SET_MENU_STATISTICS: (state, menuStatistics) => {
    state.menuStatistics = menuStatistics
  }
}
const actions = {
  getMenuStatistics({ commit }) {
    new ExtraPhotoQueryStatisticsRequest().directCall(response => {
      commit('SET_MENU_STATISTICS', response.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
