<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view v-if="!$route.meta.link" :key="key" />
      </keep-alive>
    </transition>

    <!-- iframe页 -->
    <component
        v-for="item in hasOpenComponentsArr"
        :key="item.name"
        :is="item.name"
        v-show="$route.path === item.path"></component>

    <iframe-toggle />
  </section>
</template>

<script>
import iframeToggle from "./IframeToggle/index"
import Metabase from "@/views/metabase";
import {iframeRoute_buffetInnerDoc, iframeRoute_metabase, iframeRoute_orderFullDetails, iframeRoute_outboundPackageIdInfo, iframeRoute_ticketDetails} from "@/router";

export default {
  name: 'AppMain',
  components: {Metabase, iframeToggle },
  data() {
    return {
      // componentsArr: [],
      metabaseMap: {},

      // metabase_iframeComponent: (resolve) => require(['@/views/metabase/index'], resolve),
      // metabase_iframeComponent: Metabase,
    }
  },
  watch: {
    $route() {
      // 判断当前路由是否iframe页
      this.initMetabaseIframePage();
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    },
    hasOpenComponentsArr() {
      // 实现懒加载，只渲染已经打开过（hasOpen:true）的iframe页
      return this.componentsArr.filter(item => item.hasOpen);
    },
    componentsArr(){
      return Object.values(this.metabaseMap)
    }
  },

  created() {
    // console.log('appMain created')
    this.initMetabaseIframePage()
  },
  methods: {
    metabase_iframeComponent(){
      return (resolve) => {
        console.log('动态加载组件 @/views/metabase/index')
        return require(['@/views/metabase/index'], resolve)
      }
    },
    buffetInnerDoc_iframeComponent(){
      return (resolve) => {
        console.log('动态加载组件 @/views/generated/order/BuffetInnerDoc')
        return require(['@/views/generated/order/BuffetInnerDoc'], resolve)
      }
    },
    ticketDetails_iframeComponent(){
      return (resolve) => {
        console.log('动态加载组件 @/views/ticket/details')
        return require(['@/views/ticket/details'], resolve)
      }
    },
    orderFullDetails_iframeComponent(){
      return (resolve) => {
        console.log('动态加载组件 @/views/generated/order/OrderFullDetails')
        return require(['@/views/generated/order/OrderFullDetails'], resolve)
      }
    },
    outboundPackageIdInfo_iframeComponent(){
      return (resolve) => {
        console.log('动态加载组件 @/views/generated/outboundPackage/index')
        return require(['@/views/generated/outboundPackage/index'], resolve)
      }
    },
    initMetabaseIframePage() {
      // 参考项目 https://blog.csdn.net/saowen163/article/details/106318374  ->  https://github.com/jmx164491960/vue-iframe-demo 的实现
      console.log('isOpenIframePage',this.$route,this.metabaseMap)

      let component;
      if (this.$route.name === iframeRoute_metabase) {
        // metabase
        component = this.metabase_iframeComponent()
      } else if (this.$route.name === iframeRoute_buffetInnerDoc) {
        // buffetInnerDoc
        component = this.buffetInnerDoc_iframeComponent()
      } else if (this.$route.name === iframeRoute_ticketDetails) {
        // ticketDetails
        // component = this.ticketDetails_iframeComponent()
        // 工单详情缓存, 客服那边很容易浏览器内存溢出, 打字要很久才能显示出来
      }else if (this.$route.name === iframeRoute_orderFullDetails) {
        // orderFullDetails
        component = this.orderFullDetails_iframeComponent()
      } else if (this.$route.name === iframeRoute_outboundPackageIdInfo) {
        // orderFullDetails
        component = this.outboundPackageIdInfo_iframeComponent()
      } else {
        return;
      }

      // 根据当前路由设置hasOpen
      const routePath = this.$route.path;
      let target = this.metabaseMap[routePath]
      if(!target){
        target = {
          name: routePath.replace(/\//g,''), // 替换斜杠需要这样
          path: routePath,
          hasOpen: false, // 是否打开过，默认false
          component: component, // 组件文件的引用
        }

        this.metabaseMap[routePath] = target

        this.metabaseMap = {...this.metabaseMap}

        // https://blog.csdn.net/m0_37894494/article/details/120539267  vue动态添加组件
        // 在当前的vue实例上动态添加组件
        this.$options.components[target.name] = target.component
        console.log('手动加载组件到当前vue实例',target,this.$options.components)
      }

      target.hasOpen = true
    }
  }

}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>
