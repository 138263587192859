import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
import WarehousePdaLayout from '@/layout/WarehousePdaLayout'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 【重要】当设置 true 的时候该路由不会再侧边栏出现 如 401，login 等页面，或者如一些编辑页面 /edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * path: '/login',                // 【重要】访问的 URL 路径
 * component: Layout,             // 【重要】对应的组件；也可以是 (resolve) => require(['@/views/login'], resolve),
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 【重要】设定路由的名字，一定要填写不然使用 <keep-alive> 时会出现各种问题
 * meta : {
    noCache: true                // 【重要】如果设置为 true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 【重要】设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 【重要】设置该路由的图标，对应路径 src/assets/icons/svg
    breadcrumb: false            // 如果设置为 false，则不会在 breadcrumb 面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

export const iframeRoute_metabase = "metabase";
export const iframeRoute_orderFullDetails = "orderFullDetails";
export const iframeRoute_buyerOrderFullDetails = "buyerOrderFullDetails";
export const iframeRoute_buffetInnerDoc = "buffetInnerDoc";
export const iframeRoute_ticketDetails = "ticketDetails";
export const iframeRoute_outboundPackageIdInfo = "outboundPackageIdInfo";

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/sso',
    component: (resolve) => require(['@/views/sso'], resolve),
    hidden: true
  },
  {
    path: '/social-login',
    component: (resolve) => require(['@/views/socialLogin'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/forecastDetails/:trackingNo',
    component: (resolve) => require(['@/views/generated/inboundPackage/ForecastDetails'], resolve),
    hidden: true
  },
  {
    path: '',
    component: WarehousePdaLayout,
    hidden: true,
    redirect: 'index',
    children: [
      {
        path: '/warehouseMobile',
        component: (resolve) => require(['@/views/generated/inboundPackage/WarehouseMobile'], resolve),
        hidden: true
      },
      {
        path: '/mobileWarehouseSign',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileWarehouseSign'], resolve),
        hidden: true
      },
      {
        path: '/mobileAdjust',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileAdjust'], resolve),
        hidden: true
      },
      {
        path: '/mobileSearchTag',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileSearchTag'], resolve),
        hidden: true
      },
      {
        path: '/mobileWaitToShelf',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileWaitToShelf'], resolve),
        hidden: true
      },
      {
        path: '/mobileReturnPick',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileReturnPick'], resolve),
        hidden: true
      },
      {
        path: '/mobileWarehouseReturnSent',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileWarehouseReturnSent'], resolve),
        hidden: true
      },
      {
        path: '/mobilePick',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobilePick'], resolve),
        hidden: true
      },
      {
        path: '/mobilePickNew',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobilePickNew'], resolve),
        hidden: true
      },
      {
        path: '/mobilePickSorting',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobilePickSorting'], resolve),
        hidden: true
      },
      {
        path: '/mobilePackValidate',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobilePackValidate'], resolve),
        hidden: true
      },
      {
        path: '/mobileOutboundPackagePick',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileOutboundPackagePick'], resolve),
        hidden: true
      },
      {
        path: '/mobileOutboundPackageAdjust',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileOutboundPackageAdjust'], resolve),
        hidden: true
      },
      {
        path: '/mobileOutboundPackagePasteWaybill',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileOutboundPackagePasteWaybill'], resolve),
        hidden: true
      },
      {
        path: '/mobileOutboundPackageUpToPallet',
        component: (resolve) => require(['@/views/generated/inboundPackage/MobileOutboundPackageUpToPallet'], resolve),
        hidden: true
      },
      {
        path: '/warehouse/shelf-details',
        component: (resolve) => require(['@/views/generated/storageShelf/ShelfDetails'], resolve),
        hidden: true
      },
    ]
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    redirect: 'index',
    children: [
      // {
      //   path: 'index',
      //   component: (resolve) => require(['@/views/index'], resolve),
      //   name: '首页',
      //   meta: {title: '首页', icon: 'dashboard', affix: true}
      // },
      {
        path: '/*/metabase/:id', //配置正则匹配  /ff/metabase/11,/ff/ggg/metabase/11, 这种都能匹配到
        iframeComponent: (resolve) => require(['@/views/metabase/index'], resolve),
        name: iframeRoute_metabase,
        meta: {title: 'metabase', icon: 'user',extraMetaInfo: 'test1234'},
        beforeEnter: (to, from, next) => {
          // 同一个路由只会进入一次,所以导致多个相同的路由, to.meta.title都是一样的了,这个是放在router.beforeEach处理的
          // console.log('beforeEnter /order/metabase/',to,from,next)
          // if (from.meta) {
          //   to.meta.title = 'metabase: '+to.params.id;
          // }
          next();
        },
      },
      {
        path: '/*/innerDoc/:docType',
        // AppMain.vue 里面会由于 v-if="!$route.meta.link" 而显示2个出来, 随便加一个link就可以了,但不知道为啥iframeRoute_metabase的没有link也只显示了一个
        // 原因是因为路由的字段用的是iframeComponent而不是component,因此 AppMain.vue 的router-view匹配不到,所以不会显示
        iframeComponent: (resolve) => require(['@/views/generated/order/BuffetInnerDoc'], resolve),
        name: iframeRoute_buffetInnerDoc,
        meta: {title: 'innerDoc', icon: 'user',extraMetaInfo: 'test12345'},
      },
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: {title: '个人中心', icon: 'user'}
      }
    ]
  },  {
    path: '/order',
    component: Layout,
    hidden: true,
    children: [{
      path: 'info/:orderNo',
      component: (resolve) => require(['@/views/generated/order/index'], resolve),
      name: 'orderInfo',
      meta: {title: '订单:orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
      beforeEnter: (to, from, next) => {
        // 根据自己业务判断
        if (from.meta) {
          to.meta.title = '订单:'+to.params.orderNo;
        }
          next();
        },
      },
      {
        path: 'purchase/:orderNo',
        component: (resolve) => require(['@/views/generated/orderItem/index'], resolve),
        name: 'orderItemsInfo',
        meta: {title: '按订单采购:orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '按订单采购:'+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'purchase-info/:id',
        component: (resolve) => require(['@/views/generated/purchaseOrder/index'], resolve),
        name: 'purchaseInfo',
        meta: {title: '采购详情:id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '采购订单:'+to.params.id;
          }
          next();
        },
      },
      {
        path: 'purchase-info-by-order/:orderNo',
        component: (resolve) => require(['@/views/generated/purchaseOrder/index'], resolve),
        name: 'purchaseInfoByOrder',
        meta: {title: '采购详情:orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '采购详情: '+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'order-item-info/:id',
        component: (resolve) => require(['@/views/generated/orderItem/index'], resolve),
        name: 'orderItemInfo',
        meta: {title: '订单产品id: id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '订单产品id: '+to.params.id;
          }
          next();
        },
      },
      {
        path: 'inbound-package/:id',
        component: (resolve) => require(['@/views/generated/inboundPackage/index'], resolve),
        name: 'inboundPackageInfo',
        meta: {title: '入库包裹id: id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '入库包裹id: '+to.params.id;
          }
          next();
        },
      },
      {
        path: 'inbound-package-by-order/:orderNo',
        component: (resolve) => require(['@/views/generated/inboundPackage/index'], resolve),
        name: 'inboundPackageInfoByOrder',
        meta: {title: '入库包裹: orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '入库详情: '+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'inbound-package-tag/:tagNo',
        component: (resolve) => require(['@/views/generated/inboundPackage/index'], resolve),
        name: 'inboundPackageInfo',
        meta: {title: '入库包裹搜索: tagNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '入库包裹搜索: '+to.params.tagNo;
          }
          next();
        },
      },
      {
        path: 'outbound-package/:id',
        iframeComponent: (resolve) => require(['@/views/generated/outboundPackage/index'], resolve),
        name: iframeRoute_outboundPackageIdInfo,
        meta: {title: '出库包裹id: id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '出库包裹id: '+to.params.id;
          }
          next();
        },
      },
      {
        path: 'outbound-package-by-order/:orderNo',
        component: (resolve) => require(['@/views/generated/outboundPackage/index'], resolve),
        name: 'outboundPackageByOrder',
        meta: {title: '出库包裹: orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '出库包裹: '+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'item-refund-record-by-order/:orderNo',
        component: (resolve) => require(['@/views/generated/itemRefundRecord/index'], resolve),
        name: 'itemRefundRecordByOrder',
        meta: {title: '售后详情: orderNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '售后详情: '+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'order-full-details/:orderNo',
        iframeComponent: (resolve) => require(['@/views/generated/order/OrderFullDetails'], resolve),
        name: iframeRoute_orderFullDetails,
        meta: {title: '订单全部详情: orderNo', icon: 'user',noCache: false}, //路由不一样,因此没法缓存,后面可以优化,作用不大,ADD_CACHED_VIEW
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            // 这里如果路由参数切换,title不会变的
            to.meta.title = '订单全部详情: '+to.params.orderNo;
          }
          next();
        },
      },
      {
        path: 'buyer-order-full-details/:buyerId',
        component: (resolve) => require(['@/views/generated/order/BuyerOrderFullDetails'], resolve),
        name: iframeRoute_buyerOrderFullDetails,
        meta: {title: '买家全部详情: buyerId', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            // 这里如果路由参数切换,title不会变的
            to.meta.title = '买家全部详情: '+to.params.buyerId;
          }
          next();
        },
      },
      {
        path: 'order-item-same-search/:skuId',
        component: (resolve) => require(['@/views/generated/orderItem/index'], resolve),
        name: 'order-item-same-search',
        meta: {title: '搜同款产品', icon: 'user'},
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            // 这里如果路由参数切换,title不会变的
            to.meta.title = `搜同款产品 - skuPid: ${to.query.skuPid}, skuId: ${to.query.skuId}`;
          }
          next();
        },
      },
      {
        path: 'warehouse-outbound-package/:packageNo',
        component: (resolve) => require(['@/views/generated/warehouseOutboundPackage/PackedAndShipped'], resolve),
        name: 'warehouseOutboundPackagePackageNo',
        meta: {title: '包裹编号: packageNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '包裹编号: '+to.params.packageNo;
          }
          next();
        },
      },
      {
        path: 'warehouse-outbound-package-wait-to-pack/:packageNo',
        component: (resolve) => require(['@/views/generated/warehouseOutboundPackage/WaitToPack'], resolve),
        name: 'warehouseOutboundPackageWaitToPackPackageNo',
        meta: {title: '待打包包裹编号: packageNo', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '待打包包裹编号: '+to.params.packageNo;
          }
          next();
        },
      },
      {
        path: 'ticket/details/:ticketId',
        component: (resolve) => require(['@/views/ticket/details'], resolve),
        name: iframeRoute_ticketDetails,
        meta: {title: '工单详情: id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '工单详情: '+to.params.ticketId;
          }
          next();
        },
      },
      {
        path: 'cyjyOutboundDetails/:outboundPackageId',
        component: (resolve) => require(['@/views/generated/outboundPackage/CyjyOutboundDetails'], resolve),
        name: 'cyjyOutboundDetails',
        meta: {title: '包裹: id川邮集运出库详情', icon: 'user'},
        beforeEnter: (to, from, next) => {
          if (from.meta) {
            to.meta.title = '包裹: '+to.params.outboundPackageId+'川邮集运出库详情';
          }
          next();
        },
      },
      {
        path: 'purchaseOrderWeidianAutoAgentBuyer',
        component: (resolve) => require(['@/views/generated/purchaseOrder/PurchaseOrderWeidianAutoAgentBuyer'], resolve),
        name: 'purchaseOrderWeidianAutoAgentBuyer',
        meta: {title: '微店自动采购-买手', icon: 'user'},
      },
      {
        path: 'shelf-details/:shelfCode',
        component: (resolve) => require(['@/views/generated/storageShelf/ShelfDetails'], resolve),
        name: 'shelfDetails',
        meta: {title: '货架详情: id', icon: 'user'},
// Vue Router 如何动态设置 meta 中的 title 值？ - 掘金
// https://juejin.cn/post/7080384973923942413
        beforeEnter: (to, from, next) => {
          // 根据自己业务判断
          if (from.meta) {
            to.meta.title = '货架详情: '+to.params.shelfCode;
          }
          next();
        },
      },
    ]
  }, {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [{
        path: 'type/data/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: {title: '字典数据', icon: '', activeMenu: '/system/dict'}
      }
    ]
  }, {
    path: '/property',
    component: Layout,
    hidden: true,
    children: [{
        path: 'value/:propertyId(\\d+)',
        component: (resolve) => require(['@/views/mall/product/property/value'], resolve),
        name: 'PropertyValue',
        meta: {title: '规格数据', icon: '', activeMenu: '/product/property'}
      }
    ]
  }, {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [{
        path: 'log',
        component: (resolve) => require(['@/views/infra/job/log'], resolve),
        name: 'JobLog',
        meta: {title: '调度日志', activeMenu: '/infra/job'}
      }
    ]
  }, {
    path: '/codegen',
    component: Layout,
    hidden: true,
    children: [{
        path: 'edit/:tableId(\\d+)',
        component: (resolve) => require(['@/views/infra/codegen/editTable'], resolve),
        name: 'GenEdit',
        meta: {title: '修改生成配置', activeMenu: '/infra/codegen'}
      }
    ]
  },{
    path: '/spu',
    component: Layout,
    hidden: true,
    children: [{
        path: 'edit/:spuId(\\d+)',
        component: (resolve) => require(['@/views/mall/product/spu/save'], resolve),
        name: 'SpuEdit',
        meta: {title: '修改商品', activeMenu: '/product/spu'}
      },
      {
        path: 'add',
        component: (resolve) => require(['@/views/mall/product/spu/save'], resolve),
        name: 'SpuAdd',
        meta: {title: '添加商品', activeMenu: '/product/spu'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'oa/leave/create',
        component: (resolve) => require(['@/views/bpm/oa/leave/create'], resolve),
        name: '发起 OA 请假',
        meta: {title: '发起 OA 请假', icon: 'form', activeMenu: '/bpm/oa/leave'}
      }, {
        path: 'oa/leave/detail',
        component: (resolve) => require(['@/views/bpm/oa/leave/detail'], resolve),
        name: '查看 OA 请假',
        meta: {title: '查看 OA 请假', icon: 'view', activeMenu: '/bpm/oa/leave'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    children: [{
        path: 'manager/form/edit',
        component: (resolve) => require(['@/views/bpm/form/formEditor'], resolve),
        name: '流程表单-编辑',
        meta: {title: '流程表单-编辑', activeMenu: '/bpm/manager/form'}
      }, {
        path: 'manager/definition',
        component: (resolve) => require(['@/views/bpm/definition/index'], resolve),
        name: '流程定义',
        meta: {title: '流程定义', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'manager/model/design',
        component: (resolve) => require(['@/views/bpm/model/modelEditor'], resolve),
        name: '设计流程',
        meta: {title: '设计流程', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'process-instance/create',
        component: (resolve) => require(['@/views/bpm/processInstance/create'], resolve),
        name: '发起流程',
        meta: {title: '发起流程', activeMenu: '/bpm/task/my'}
      }, {
        path: 'process-instance/detail',
        component: (resolve) => require(['@/views/bpm/processInstance/detail'], resolve),
        name: '流程详情',
        meta: {title: '流程详情', activeMenu: '/bpm/task/my'}
      }
    ]
  }, {
    path: '/logistics',
    component: Layout,
    hidden: true,
    children: [{
      path: 'pricing/details/:pricingId(\\d+)',
      component: (resolve) => require(['@/views/logistics/logisticsPricing/details'], resolve),
      name: 'LogisticsPricingDetails',
      meta: {title: '物流产品报价明细', icon: '', activeMenu: '/logistics/logistics-pricing'}
    }
    ]
  }, {
    path: '/customer-service',
    component: Layout,
    hidden: true,
    children: [{
      path: 'ticket/details/:ticketId(\\d+)',
      component: (resolve) => require(['@/views/ticket/details'], resolve),
      name: 'TicketDetails_customerService',
      meta: {title: '工单详情', icon: '', activeMenu: '/customer-service/ticket'}
    }
    ]
  }, {
    path: '/fin',
    component: Layout,
    hidden: true,
    children: [{
      path: 'fin-logistics-bill/reconciliation/:id(\\d+)',
      component: (resolve) => require(['@/views/fin/finLogisticsBillReconciliation/details'], resolve),
      name: 'LogisticsBillReconciliation',
      meta: {title: '物流账单对账', icon: '', activeMenu: '/fin/fin-logistics-bill'},
      beforeEnter: (to, from, next) => {
        if (from.meta) {
          to.meta.title = to.query.title;
        }
        next();
      },
    },
    ]
  }, {
    path: '/riskcontrol',
    component: Layout,
    hidden: true,
    children: [{
      path: 'payment-log/:id(\\d+)/:orderNo',
      component: (resolve) => require(['@/views/rc/paymentLog/details'], resolve),
      name: 'paymentLogDetails',
      meta: {title: '支付详情', icon: '', activeMenu: '/riskcontrol/payment-log'},
      beforeEnter: (to, from, next) => {
        if (from.meta) {
          to.meta.title = "R"+to.params.orderNo;
        }
        next();
      },
    },
    ]
  },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "/",
  // mode: 'history', // 去掉url中的#
  mode: 'hash', // url中带# , 这样才能前后端打包到一起
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})
