<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>

    <div class="right-menu">
      <el-select id="selectWebsite" ref="currentWebsiteIdRef" class="right-menu-item hover-effect" v-model="currentWebsiteId" placeholder="请选择代购网站"
                 @change="(websiteId)=>setCurrentWebsiteId(websiteId,true)">
        <el-option v-for="website in myWebsites"
                   :key="website.key" :label="website.value" :value="website.key"/>
      </el-select>
      <el-select id="selectWarehouse" ref="currentWarehouseIdRef" class="right-menu-item hover-effect" v-model="currentWarehouseId" holder="请选择查询仓库"
                 @change="(warehouseId)=>setCurrentWarehouseId(warehouseId,true)">
        <el-option v-for="warehouse in myWarehouses" :key="warehouse.key" :label="warehouse.value"
                   :value="warehouse.key" />
      </el-select>
      <template v-if="device!=='mobile'">

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <span v-if="nickname" class="user-nickname">{{ nickname }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <router-link to="/warehouseMobile">
            <el-dropdown-item>仓储管理</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import {getPath} from "@/utils/ruoyi";
import {getCurrentWebsiteId, getCurrentWarehouseId} from "@/utils/auth";
import {deleteForm} from "@/api/bpm/form";

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc
  },
  data(){
    return {
      currentWebsiteId: '',
      currentWarehouseId: '',
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'nickname',
      'device',
      'warehouses',
      'websites',
    ]),
    myWarehouses(){
      return this.$store.getters.warehouses;
    },
    myWebsites(){
      return this.$store.getters.websites;
    },
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    }
  },
  created() {
   const currentWebsiteId = getCurrentWebsiteId()
    if(this.myWebsites.filter(w => w.key === currentWebsiteId).length>0){
      this.setCurrentWebsiteId(currentWebsiteId,false);
    }else{
      this.setCurrentWebsiteId(this.myWebsites[0].key,false);
    }

   const currentWarehouseId = getCurrentWarehouseId()
    if (this.myWarehouses.filter(w => w.key === currentWarehouseId).length>0) {
      this.setCurrentWarehouseId(currentWarehouseId,false);
    }else {
      this.setCurrentWarehouseId(this.myWarehouses[0].key,false);
    }
  },
  methods: {
    setCurrentWebsiteId(websiteId, confirm){
      if(confirm){
        const oldWebsiteId = this.$refs.currentWebsiteIdRef.value
        // todo 设置到localStorage
        // todo 区分是手动切换还是初始化设置的,手动切换的话要触发页面的刷新才行
        // todo 手动切换之后,直接刷新页面也是可以的,因为可能同时打开了很多窗口,没法每个窗口都触发重新搜索的,本来缓存的页面也要重新触发刷新的

        const this$ = this

        this.$modal.confirm('是否切换查询代购网站,切换会使整个页面会刷新,是否继续?').then(function() {
          this$.currentWebsiteId = websiteId
          this$.$store.dispatch('SetWebsiteId', websiteId);
          window.location.reload()
          // setTimeout(()=>{
          // },200)
        }).catch((error)=>{
          console.log('setCurrentAgentWebsite confirm error',error)
          this.currentWebsiteId = oldWebsiteId
        })
      }else{
        this.currentWebsiteId = websiteId
        this.$store.dispatch('SetWebsiteId', websiteId);
      }
    },
    setCurrentWarehouseId(warehouseId,confirm){
      if(confirm){
        const oldWarehouseId = this.$refs.currentWarehouseIdRef.value
        // todo 设置到localStorage
        // todo 区分是手动切换还是初始化设置的,手动切换的话要触发页面的刷新才行
        // todo 手动切换之后,直接刷新页面也是可以的,因为可能同时打开了很多窗口,没法每个窗口都触发重新搜索的,本来缓存的页面也要重新触发刷新的

        const this$ = this

        this.$modal.confirm('是否切换仓库,切换仓库整个页面会刷新,是否继续?').then(function() {
          this$.currentWarehouseId = warehouseId
          this$.$store.dispatch('SetWarehouseId', warehouseId);
          window.location.reload()
          // setTimeout(()=>{
          // },200)
        }).catch((error)=>{
          console.log('setCurrentWarehouseId confirm error',error)
          this.currentWarehouseId = oldWarehouseId
        })
      }else{
        this.currentWarehouseId = warehouseId
        this.$store.dispatch('SetWarehouseId', warehouseId);
      }
    },
    isMobile(){
      return navigator.userAgent.toLowerCase().includes("mobile")
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          //测试生产 前端代码是打包到后端的,所以退登后重载的路径变了
          location.href = getPath('/');
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
        .user-nickname{
          margin-left: 5px;
          font-size: 14px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
