import request from '@/utils/request'

// 获得物流产品分页
export function listCountries(query) {
  return request({
    url: '/logistics/country/list-countries',
    method: 'get',
    params: query
  })
}

