import { Message, MessageBox, Notification, Loading } from 'element-ui'
import globalUtils from "@/ts/GlobalUtils";

let loadingInstance;

export default {
  // 消息提示
  msg(content) {
    Message.info(content)
  },
  // 错误消息
  msgError(content) {
    Message.error(content)
  },
  // 成功消息
  msgSuccess(content) {
    Message.success(content)
  },
  // 警告消息
  msgWarning(content) {
    Message.warning(content)
  },
  // 弹出提示
  alert(content) {
    MessageBox.alert(content, "系统提示")
  },
  // 错误提示
  alertError(content) {
    MessageBox.alert(content, "系统提示", { type: 'error' })
  },
  // 成功提示
  alertSuccess(content) {
    MessageBox.alert(content, "系统提示", { type: 'success' })
  },
  // 警告提示
  alertWarning(content) {
    MessageBox.alert(content, "系统提示", { type: 'warning' })
  },
  // 通知提示
  notify(content) {
    Notification.info(content)
  },
  // 错误通知
  notifyError(content) {
    Notification.error(content);
  },
  // 成功通知
  notifySuccess(content) {
    Notification.success(content)
  },
  // 警告通知
  notifyWarning(content) {
    Notification.warning(content)
  },
  // 确认窗体
  confirm(content) {
    // https://blog.csdn.net/xljx_1/article/details/126197106  ElementUI MessageBox 适配移动端，修改宽度
    return MessageBox.confirm(content, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning",
      customClass: 'messageBoxClass',
    })
  },
  confirmError(content) {
    // https://blog.csdn.net/xljx_1/article/details/126197106  ElementUI MessageBox 适配移动端，修改宽度
    return MessageBox.confirm(content, "系统提示异常", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "error",
      customClass: 'messageBoxClass',
    })
  },
  // 提交内容
  prompt(content) {
    return MessageBox.prompt(content, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning",
    })
  },
  promptWithRemark(content) {
    return MessageBox.prompt(content, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning",
      inputValidator:(inputValue)=>{
        if(globalUtils.isEmpty(inputValue)){
          return "请按要求输入"
        }
        return true;
      },
    }).then(messageBoxData => {
      // if(messageBoxData.action === 'confirm' && globalUtils.isEmpty(messageBoxData.value)){
      //   this.msgError("备注不能为空")
      //   throw new Error("备注不能为空")
      // }
      // console.log("messageBoxData",messageBoxData)
      return messageBoxData.value
    })
  },
  promptWithInfoEqual(content,info) {
    return MessageBox.prompt(`${content} 请输入"${info}", 以防止误操作`, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning",
      inputValidator:(inputValue)=>{
        if(globalUtils.isNotEmpty(inputValue) && info === inputValue.trim()){
          return true
        }
        return `请输入"${info}", 以防止误操作`;
      },
    }).then(messageBoxData => {
      // if(messageBoxData.action === 'confirm' && globalUtils.isEmpty(messageBoxData.value)){
      //   this.msgError("备注不能为空")
      //   throw new Error("备注不能为空")
      // }
      // console.log("messageBoxData",messageBoxData)
      return messageBoxData.value
    })
  },
  // 打开遮罩层
  loading(content) {
    loadingInstance = Loading.service({
      lock: true,
      text: content,
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    })
  },
  // 关闭遮罩层
  closeLoading() {
    loadingInstance.close();
  }
}
