const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  userId: state => state.user.id,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  nickname: state => state.user.nickname,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  warehouses: state => state.user.warehouses,
  currentWarehouseId: state => state.user.currentWarehouseId,
  websites: state => state.user.websites,
  currentWebsiteId: state => state.user.currentWebsiteId,
  permission_routes: state => state.permission.routes,
  permission_addRoutes: state => state.permission.addRoutes,
  // 工具栏
  topbarRouters:state => state.permission.topbarRouters,
  defaultRoutes:state => state.permission.defaultRoutes,
  sidebarRouters:state => state.permission.sidebarRouters,
  // 数据字典
  dict_datas: state => state.dict.dictDatas,
  countries: state => state.countries.countries,
  abnormalPackageCount: state => state.inbound.abnormalPackageCount,
}
export default getters
