import { getUnRepliedTicketCount } from '@/api/ticket/ticket';

const state = {
  /**
   * 数据字典 MAP
   * key：数据字典大类枚举值 dictType
   * dictValue：数据字典小类数值 {dictValue: '', dictLabel: ''} 的数组
   */
  unRepliedCount: 0,
  replyContent: ''
}

const mutations = {
  SET_TICKET_DATES: (state, ticketDatas) => {
    state.unRepliedCount = ticketDatas
  },
  UPDATE_REPLY_CONTENT: (state, replyContent) => {
    state.replyContent = replyContent
  }
}
const actions = {
  getUnrepliedCount({ commit }) {
    getUnRepliedTicketCount().then(res => {
      if (!res || !res.data) {
        return;
      }
      commit('SET_TICKET_DATES', res.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
