//这是一个vuex模块，用于存储国家信息

import {listCountries} from "@/api/country/country";

const state = {
  countries: []
}

const mutations = {
  SET_COUNTRIES: (state, countries) => {
    state.countries = countries
  }
}

const actions = {
  loadCountries({ commit }) {
    listCountries().then(res => {
      if (!res || !res.data) {
        return
      }
      commit('SET_COUNTRIES', res.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
