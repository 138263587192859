import {GetPackageCountRequest, GetPackageCountRequest$Response} from "@/ts/InboundController";

const state = {
  packageCount: new GetPackageCountRequest$Response()
}

const mutations = {
  SET_PACKAGE_COUNT: (state, packageCount) => {
    state.packageCount = packageCount
  }
}
const actions = {
  getPackageCount({ commit }) {
    new GetPackageCountRequest().directCall(response => {
      commit('SET_PACKAGE_COUNT', response.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
