import axios from 'axios'
import {Message, MessageBox, Notification} from 'element-ui'
import store from '@/store'
import qs from 'qs'
import {getAccessToken, getCurrentWebsiteId, getRefreshToken, getTenantId, setToken} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import {getPath, getTenantEnable} from "@/utils/ruoyi";
import {refreshToken} from "@/api/login";

// 需要忽略的提示。忽略后，自动 Promise.reject('error')
const ignoreMsgs = [
  "无效的刷新令牌", // 刷新令牌被删除时，不用提示
  "刷新令牌已过期" // 使用刷新令牌，刷新获取新的访问令牌时，结果因为过期失败，此时需要忽略。否则，会导致继续 401，无法跳转到登出界面
]

export const pleaseReloginMsg = "无效的会话，或者会话已过期，请重新登录。"

// 是否显示重新登录
export let isRelogin = { show: false };
// Axios 无感知刷新令牌，参考 https://www.dashingdog.cn/article/11 与 https://segmentfault.com/a/1190000020210980 实现
// 请求队列
let requestList = []
// 是否正在刷新中
let isRefreshToken = false

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API + '/admin-api/', // 此处的 /admin-api/ 地址，原因是后端的基础路径为 /admin-api/
  // 超时:10分钟
  timeout: 600000,
  // 禁用 Cookie 等信息
  withCredentials: false,
})

const serviceOther = axios.create({})
const ignoreGlobalValueUrls = ["/system/role/update"]

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getAccessToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getAccessToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // 设置租户
  if (getTenantEnable()) {
    const tenantId = getTenantId();
    if (tenantId) {
      config.headers['tenant-id'] = tenantId;
    }
  }
  config.headers['website-id'] = getCurrentWebsiteId();

  if(ignoreGlobalValueUrls.filter(url => url === config.url).length===0){
    config.data = setCurrentGlobalValue(config.data);
    config.params = setCurrentGlobalValue(config.params);
  }

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + (config.url.indexOf('?')===-1?'?':'&');
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      const part = encodeURIComponent(propName) + '='
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            const subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(async res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = res.data.msg || errorCode[code] || errorCode['default']
  if (ignoreMsgs.indexOf(msg) !== -1) { // 如果是忽略的错误码，直接返回 msg 异常
    return Promise.reject(msg)
  } else if (code === 401) {
    // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
    if (!isRefreshToken) {
      isRefreshToken = true;
      // 1. 如果获取不到刷新令牌，则只能执行登出操作
      if (!getRefreshToken()) {
        return handleAuthorized();
      }
      // 2. 进行刷新访问令牌
      try {
        const refreshTokenRes = await refreshToken()
        // 2.1 刷新成功，则回放队列的请求 + 当前请求
        setToken(refreshTokenRes.data)
        requestList.forEach(cb => cb())
        return service(res.config)
      } catch (e) {// 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
        // 2.2 刷新失败，只回放队列的请求
        requestList.forEach(cb => cb())
        // 提示是否要登出。即不回放当前请求！不然会形成递归
        return handleAuthorized();
      } finally {
        requestList = []
        isRefreshToken = false
      }
    } else {
      // 添加到队列，等待刷新获取到新的令牌
      return new Promise(resolve => {
        requestList.push(() => {
          res.config.headers['Authorization'] = 'Bearer ' + getAccessToken() // 让每个请求携带自定义token 请根据实际情况自行修改
          resolve(service(res.config))
        })
      })
    }
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    if (msg === '无效的刷新令牌') { // hard coding：忽略这个提示，直接登出
      console.log(msg)
    } else {
      Notification.error({
        title: msg
      })
    }
    return Promise.reject('error')
  } else {
    // code === 200
    const data = res.data
    if (typeof data === 'string' && data.length > 0) {
      // 加上这个提示, 不做其他的处理
      // 后面如果还出现其他的情况再做完善
      console.log('后端接口返回数据序列化可能异常: ', res.request.responseURL, data);
      Message({
        message: `后端接口返回数据序列化可能异常, ${res.request.responseURL}`,
        type: 'error'
      });
    }

    return res.data
  }
}, error => {
  // error类型是AxiosError
    console.log('err' + error,error)

    let message = error.message
    let {response} = error;

    let data
    if(response){
      const status = response.status
      data = response.data
      if(status === 400 || status === 500){
        message = response.data.msg
      }
    }

    if(data instanceof Blob){
      // 下载文件时后端报错
      data.text().then(dataString =>{
        // data：指成功读取到的内容
        const json=JSON.parse(dataString)
        message = json.msg
        Message({
          message: message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(error)

      }).catch(err =>{
        //err： 指读取失败后返回的错误内容
        console.log('Blob err' + error,error)
      })
    }else{
      if(!message){
        //message为空
        message = "未知异常信息";
      }else if (message === "Network Error") {
        message = "后端接口连接异常";
      } else if (message.indexOf("timeout")>-1) {
        message = "系统接口请求超时";
      } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
      }
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export function getBaseHeader() {
  return {
    'Authorization': "Bearer " + getAccessToken(),
    'tenant-id': getTenantId(),
  }
}

function handleAuthorized() {
  if (!isRelogin.show) {
    isRelogin.show = true;
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      isRelogin.show = false;
      store.dispatch('LogOut').then(() => {
        location.href = getPath('/');
      })
    }).catch(() => {
      isRelogin.show = false;
    });
  }
  return Promise.reject(pleaseReloginMsg)
}

function request(axiosRequestConfig){
  // 由请求参数来控制接口调用的超时时间
  if(axiosRequestConfig.data && axiosRequestConfig.data.timeout){
    axiosRequestConfig.timeout = axiosRequestConfig.data.timeout
  }
  if(axiosRequestConfig.params && axiosRequestConfig.params.timeout){
    axiosRequestConfig.timeout = axiosRequestConfig.params.timeout
  }

  return service(axiosRequestConfig)
}

function setCurrentGlobalValue(params){

  if(!params){
    params = {warehouseId: store.getters.currentWarehouseId,websiteId: store.getters.currentWebsiteId};
  }else if(typeof params === 'number'){
    // do nothing
  } else if(typeof params != 'string'){
    if(!params.websiteId || store.getters.websites.filter(website => website.key === params.websiteId).length===0){
      params.websiteId = store.getters.currentWebsiteId
    }
    if(!params.warehouseId || store.getters.warehouses.filter(warehouse => warehouse.key === params.warehouseId).length===0){
      params.warehouseId = store.getters.currentWarehouseId
    }
  }
  return params
}

function requestGet(path,params) {
  return request({
    url: path,
    method: "GET",
    params: params
  })
}

function requestGetBlob(path,params) {
  return request({
    url: path,
    method: "GET",
    params: params,
    responseType: 'blob'
  })
}

function requestPostForm(path,params) {

  // 表单的提交需要在stringify之前补齐warehouseId
  setCurrentGlobalValue(params)

  return request({
    url: path,
    method: "POST",
    data: qs.stringify(params),
    headers:{
      'Content-Type':'application/x-www-form-urlencoded'
    }
  })
}

function requestPostFormUpload(path,params) {
  return request({
    url: path,
    method: "POST",
    data: params,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  })
}

function requestPostBody(path,params){
  return request({
    url: path,
    method: "POST",
    // 后端使用@RequestBody接收
    data: params,
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

function requestPostBodyWithQuery(path,bodyParams,queryParam){
  if(queryParam === undefined ||queryParam === null){
    return requestPostBody(path,bodyParams)
  }

  const newPath = path+'?'+qs.stringify(queryParam)

  return request({
    url: newPath,
    method: "POST",
    // 后端使用@RequestBody接收
    data: bodyParams,
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

function requestPutBody(path,params){
  return request({
    url: path,
    method: "PUT",
    // 后端使用@RequestBody接收
    data: params,
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

function requestDelete(path,params) {
  return request({
    url: path,
    method: "DELETE",
    params: params
  })
}

service.directRequestOther = serviceOther
service.directRequest = request
service.requestGet = requestGet
service.requestGetBlob = requestGetBlob
service.requestPostForm = requestPostForm
service.requestPostFormUpload = requestPostFormUpload
service.requestPostBody = requestPostBody
service.requestPostBodyWithQuery = requestPostBodyWithQuery
service.requestPutBody = requestPutBody
service.requestDelete = requestDelete

axios.service = service

export default service
