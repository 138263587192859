<template>
  <div class="app-container" style="padding: 0">
    <el-button v-if="loading" :loading="loading" type="text"></el-button>
    <iframe ref="iframe" @load="adjustHeight" :src=url style="border: 0; width: 100%; height: 870px;"></iframe>
  </div>
</template>
<script>
import { getMetabaseUrl } from "@/api/metabase/metabase"
export default {
  name: 'Metabase',
  data() {
    return {
      url: '',
      loading: false,
    }
  },
  created: function () {
    // let pathAry = this.$route.path.split("/");
    // let id = pathAry[pathAry.length - 1]
    const id = this.$route.params.id;
    this.loadUrl(id)
  },
  // vue生命周期钩子函数 -- 更新之后
  updated() {
    if (this.$refs.iframe) {
      this.$refs.iframe.onload = () => {
        // 加载成功
        console.log('iframe加载完成')
        this.loading = false;
      };
    }
  },
  methods: {
    loadUrl(id) {
      this.loading = true
      // 根据id获取metabase的url
      getMetabaseUrl({id:id}).then(res => {
        this.url = res.data
      })
    },
    adjustHeight() {
      const iframe = this.$refs.iframe;
      try {
        iframe.style.height = `${iframe.contentDocument.body.scrollHeight}px`;
      } catch (error) {
        console.log('Unable to adjust iframe height due to cross-origin restrictions.');
      }
    },
  },

}
</script>
