import router, {iframeRoute_buffetInnerDoc, iframeRoute_buyerOrderFullDetails, iframeRoute_metabase, iframeRoute_orderFullDetails} from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getAccessToken } from '@/utils/auth'
import { isRelogin,pleaseReloginMsg } from '@/utils/request'
import * as globalUtils from "@/ts/GlobalUtils";
import {getFirstMenuPath} from "@/ts/tsCommon";

NProgress.configure({ showSpinner: false })

// 增加三方登陆 update by 芋艿
const whiteList = ['/login', '/social-login',  '/auth-redirect', '/bind', '/register', '/oauthLogin/gitee']

const routePath_metaTitle_map = {}

router.beforeEach((to, from, next) => {

  // 这里每次路由切换都回调过来
  if(to.name === iframeRoute_metabase){
    // 多个路由公用了一个组件,只能在这里去改to.meta.title才可以
    // todo 考虑提取对应的菜单的名称,遍历一次所有路由即可拿到
    const metaTitle = routePath_metaTitle_map[to.path]
    if(metaTitle){
      to.meta.title = metaTitle;
    }
  }else if(to.name === iframeRoute_orderFullDetails){
    to.meta.title = '订单全部详情: '+to.params.orderNo;
  }else if(to.name === iframeRoute_buyerOrderFullDetails){
    to.meta.title = '买家全部详情: '+to.params.buyerId;
  }else if(to.name === iframeRoute_buffetInnerDoc){
    to.meta.title = '文档: '+to.params.docType;
  }

  NProgress.start()
  if (getAccessToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        //todo 初始化仓库这些 都要改成使用 vuex

        isRelogin.show = true
        // 获取字典数据 add by 芋艿
        store.dispatch('dict/loadDictDatas')
        store.dispatch('ticket/getUnrepliedCount')
        store.dispatch('countries/loadCountries')
        store.dispatch('inbound/getPackageCount')
        store.dispatch('valueAddService/getMenuStatistics')
        // 判断当前用户是否已拉取完 user_info 信息
        store.dispatch('GetInfo').then(() => {

          globalUtils.initAfterLogin()

          isRelogin.show = false
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据 roles 权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表

            // console.log('accessRoutes',accessRoutes)
            for (let accessRoute of accessRoutes) {
              const routePath = accessRoute.path
              if(accessRoute.children){
                for (let accessRouteChild of accessRoute.children) {
                  if(accessRouteChild.meta){
                    routePath_metaTitle_map[routePath+'/'+accessRouteChild.path] = accessRouteChild.meta.title
                  }
                }
              }
            }
            // console.log('router.options.routes',router.options.routes)
            // console.log('routePath_metaTitle_map',routePath_metaTitle_map)

            if(to.path === '/index'){
              next({path:getFirstMenuPath(accessRoutes), replace: true }) // hack方法 确保addRoutes已完成
            }else {
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            }
          })
        }).catch(error => {
          //error refer `buffet-ui-admin/src/utils/request.js:204`
          if(error === pleaseReloginMsg || window.location.host.indexOf('localhost') === -1){
            // pleaseReloginMsg || 非本地环境
            store.dispatch('LogOut').then(() => {
              Message.error(error)
              next({ path: '/' })
            })
          }else{
            //本地开发,用的localhost,调试打断点经常会退出登录,这里就不退出
            console.log('store.dispatch(GetInfo)遇到异常',error,error === pleaseReloginMsg)
          }
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      const redirect = encodeURIComponent(to.fullPath) // 编码 URI，保证参数跳转回去后，可以继续带上
      next(`/login?redirect=${redirect}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

